import * as React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import Seo from "../components/seo"
import TitleBlock from "../components/titleBlockPages"
import FullWidthCTA from "../components/fullWidthCTABlock"
import DesignDeliver from "../components/design-deliver"
import Testimonials from "../components/testimonials"
import ContactForm from "../components/footerContactBlock"


const PromoClothing = ({ data }) => {
  ///page details
  const pageDataEdges = data.allPagepromoclothingJson.edges[0].node;
  const testimonialData = data.allTestimonialshomeJson.edges;
  const nodes = testimonialData.map((item) => { 
    return item.node;
  })
  return (
    <Layout>
      <Seo
        title="Embroidered Promotional Clothing & Printed Promotional Wear"
        description="Get all of your promotional clothing printing with Tkings. From school wear to work wear we have you covered in Milton Keynes"
      />
      <TitleBlock
        pageTitle={pageDataEdges.pageTitle}
        pageSubTitle={pageDataEdges.pageSubTitle}
        pageTitleBody={pageDataEdges.pageTitleBody}
        img={pageDataEdges.pageTitleImg}
      />
      <FullWidthCTA
        lsH3={'Whether you’re exhibiting at a trade show or breaking the team out of an escape room, your brand goes wherever you do.'}
        rsBody={'So, make sure wherever you go, whatever you’re doing, your brand looks sharp and memorable.'}
      />
      <DesignDeliver  sectionName="promotional clothing" />
      <Testimonials
        bg={"primary"}
        sectionTitleBlock={'What makes us different?'}
        sectionCopy={'More than just pens & polo shirts, we’re experts in brand expression & lasting impressions.'}
        nodes={nodes}
      />
      <ContactForm />
    </Layout>
  )
}
export const query = graphql`
  query MyQuery {
    
    allTestimonialshomeJson {
        edges {
        node {
            name
            copy
            img {
              childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
              }
              
            }
        }
        }
    }

    allPagepromoclothingJson {
      edges {
        node {
          pageTitleBody
          pageTitle
          pageSubTitle
          pageTitleImg {
                childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                }
              }

          storyBlockBody
          storyBlockTitle
          storyBlockSubTitle
          storyBlockImg {
                childImageSharp {
                    gatsbyImageData(
                      width: 1600
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                }
              }
        }
      }
    }
}`
export default PromoClothing
